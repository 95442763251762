// File generated from our OpenAPI spec by Stainless. See CONTRIBUTING.md for details.
import { APIResource } from "../resource.mjs";
export class Embeddings extends APIResource {
  /**
   * Creates an embedding vector representing the input text.
   */
  create(body, options) {
    return this._client.post('/embeddings', {
      body,
      ...options
    });
  }
}
(function (Embeddings) {})(Embeddings || (Embeddings = {}));
