import { ContentFilterFinishReasonError, LengthFinishReasonError, OpenAIError } from 'openai/error';
export function makeParseableResponseFormat(response_format, parser) {
  const obj = {
    ...response_format
  };
  Object.defineProperties(obj, {
    $brand: {
      value: 'auto-parseable-response-format',
      enumerable: false
    },
    $parseRaw: {
      value: parser,
      enumerable: false
    }
  });
  return obj;
}
export function isAutoParsableResponseFormat(response_format) {
  return response_format?.['$brand'] === 'auto-parseable-response-format';
}
export function makeParseableTool(tool, {
  parser,
  callback
}) {
  const obj = {
    ...tool
  };
  Object.defineProperties(obj, {
    $brand: {
      value: 'auto-parseable-tool',
      enumerable: false
    },
    $parseRaw: {
      value: parser,
      enumerable: false
    },
    $callback: {
      value: callback,
      enumerable: false
    }
  });
  return obj;
}
export function isAutoParsableTool(tool) {
  return tool?.['$brand'] === 'auto-parseable-tool';
}
export function maybeParseChatCompletion(completion, params) {
  if (!params || !hasAutoParseableInput(params)) {
    return {
      ...completion,
      choices: completion.choices.map(choice => ({
        ...choice,
        message: {
          ...choice.message,
          parsed: null,
          tool_calls: choice.message.tool_calls ?? []
        }
      }))
    };
  }
  return parseChatCompletion(completion, params);
}
export function parseChatCompletion(completion, params) {
  const choices = completion.choices.map(choice => {
    if (choice.finish_reason === 'length') {
      throw new LengthFinishReasonError();
    }
    if (choice.finish_reason === 'content_filter') {
      throw new ContentFilterFinishReasonError();
    }
    return {
      ...choice,
      message: {
        ...choice.message,
        tool_calls: choice.message.tool_calls?.map(toolCall => parseToolCall(params, toolCall)) ?? [],
        parsed: choice.message.content && !choice.message.refusal ? parseResponseFormat(params, choice.message.content) : null
      }
    };
  });
  return {
    ...completion,
    choices
  };
}
function parseResponseFormat(params, content) {
  if (params.response_format?.type !== 'json_schema') {
    return null;
  }
  if (params.response_format?.type === 'json_schema') {
    if ('$parseRaw' in params.response_format) {
      const response_format = params.response_format;
      return response_format.$parseRaw(content);
    }
    return JSON.parse(content);
  }
  return null;
}
function parseToolCall(params, toolCall) {
  const inputTool = params.tools?.find(inputTool => inputTool.function?.name === toolCall.function.name);
  return {
    ...toolCall,
    function: {
      ...toolCall.function,
      parsed_arguments: isAutoParsableTool(inputTool) ? inputTool.$parseRaw(toolCall.function.arguments) : inputTool?.function.strict ? JSON.parse(toolCall.function.arguments) : null
    }
  };
}
export function shouldParseToolCall(params, toolCall) {
  if (!params) {
    return false;
  }
  const inputTool = params.tools?.find(inputTool => inputTool.function?.name === toolCall.function.name);
  return isAutoParsableTool(inputTool) || inputTool?.function.strict || false;
}
export function hasAutoParseableInput(params) {
  if (isAutoParsableResponseFormat(params.response_format)) {
    return true;
  }
  return params.tools?.some(t => isAutoParsableTool(t) || t.type === 'function' && t.function.strict === true) ?? false;
}
export function validateInputTools(tools) {
  for (const tool of tools ?? []) {
    if (tool.type !== 'function') {
      throw new OpenAIError(`Currently only \`function\` tool types support auto-parsing; Received \`${tool.type}\``);
    }
    if (tool.function.strict !== true) {
      throw new OpenAIError(`The \`${tool.function.name}\` tool is not marked with \`strict: true\`. Only strict function tools can be auto-parsed`);
    }
  }
}
