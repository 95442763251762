// File generated from our OpenAPI spec by Stainless. See CONTRIBUTING.md for details.
import { APIResource } from "../../../resource.mjs";
import { isRequestOptions } from "../../../core.mjs";
import * as JobsAPI from "./jobs.mjs";
import * as CheckpointsAPI from "./checkpoints.mjs";
import { CursorPage } from "../../../pagination.mjs";
export class Jobs extends APIResource {
  constructor() {
    super(...arguments);
    this.checkpoints = new CheckpointsAPI.Checkpoints(this._client);
  }
  /**
   * Creates a fine-tuning job which begins the process of creating a new model from
   * a given dataset.
   *
   * Response includes details of the enqueued job including job status and the name
   * of the fine-tuned models once complete.
   *
   * [Learn more about fine-tuning](https://platform.openai.com/docs/guides/fine-tuning)
   */
  create(body, options) {
    return this._client.post('/fine_tuning/jobs', {
      body,
      ...options
    });
  }
  /**
   * Get info about a fine-tuning job.
   *
   * [Learn more about fine-tuning](https://platform.openai.com/docs/guides/fine-tuning)
   */
  retrieve(fineTuningJobId, options) {
    return this._client.get(`/fine_tuning/jobs/${fineTuningJobId}`, options);
  }
  list(query = {}, options) {
    if (isRequestOptions(query)) {
      return this.list({}, query);
    }
    return this._client.getAPIList('/fine_tuning/jobs', FineTuningJobsPage, {
      query,
      ...options
    });
  }
  /**
   * Immediately cancel a fine-tune job.
   */
  cancel(fineTuningJobId, options) {
    return this._client.post(`/fine_tuning/jobs/${fineTuningJobId}/cancel`, options);
  }
  listEvents(fineTuningJobId, query = {}, options) {
    if (isRequestOptions(query)) {
      return this.listEvents(fineTuningJobId, {}, query);
    }
    return this._client.getAPIList(`/fine_tuning/jobs/${fineTuningJobId}/events`, FineTuningJobEventsPage, {
      query,
      ...options
    });
  }
}
export class FineTuningJobsPage extends CursorPage {}
export class FineTuningJobEventsPage extends CursorPage {}
(function (Jobs) {
  Jobs.FineTuningJobsPage = JobsAPI.FineTuningJobsPage;
  Jobs.FineTuningJobEventsPage = JobsAPI.FineTuningJobEventsPage;
  Jobs.Checkpoints = CheckpointsAPI.Checkpoints;
  Jobs.FineTuningJobCheckpointsPage = CheckpointsAPI.FineTuningJobCheckpointsPage;
})(Jobs || (Jobs = {}));
