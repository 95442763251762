import {inject, Injectable} from "@angular/core";
import {environment} from "@src/environments/environment";
import OpenAI from "openai";
import {ChatCompletionTool} from "openai/src/resources/chat/completions";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {AiRepository} from "@ai/external/api/adapter/ai.repository";

@Injectable({
    providedIn: 'root'
})
export class OpenaiClientService {

    public readonly client: OpenAI;

    private readonly aiRepository = inject(AiRepository);
    private readonly httpClient = inject(HttpClient);
    private content: string = '';

    constructor() {
        this.client = new OpenAI({
            apiKey: environment.ai.openai.key,
            dangerouslyAllowBrowser: true
        });
    }

    public async createChatCompletions(messages: OpenAI.Chat.Completions.ChatCompletionMessageParam[]): Promise<OpenAI.Chat.Completions.ChatCompletion> {

        messages = [
            {
                role: "system",
                // content: environment.ai.context
                content: this.replaceVariables(this.content)
            },
            ...messages
        ];
        const completionAPIPromise = this.client.chat.completions.create({
            model: environment.ai.openai.model,
            tools,
            tool_choice: "auto",
            messages,
            temperature: 1,
            max_tokens: 256,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0
        });
        const result = await completionAPIPromise;
        const {message} = result.choices[0];
        const {tool_calls} = message;
        messages.push(message);
        if (tool_calls) {

            for (const toolCall of tool_calls) {
                const functionName = toolCall.function.name as keyof typeof this.aiRepository.availableFunctions;
                const functionArguments = JSON.parse(toolCall.function.arguments);
                // if (functionArguments?.length) {
                // } else {
                // }
                messages.push({
                    tool_call_id: toolCall.id,
                    role: "tool",
                    content: JSON.stringify(this.aiRepository.availableFunctions[functionName](functionArguments))
                });
            }

            return await this.createChatCompletions(messages);

        }
        return result;
    }

    public async init() {
        if (this.content.length) {
            return;
        }

        await this.aiRepository.init();

        const request$ = this.httpClient.get('/assets/ai/context/en/content.md', {
            responseType: 'text'
        });
        const content = await firstValueFrom(request$);
        this.content = content;
    }

    private replaceVariables(content: string): string {
        const properties = this.aiRepository.getPropertyToReplace();
        return content.replace(/{{(.+?)}}/g, (match, p1: keyof typeof properties) => {
            return properties[p1];
        });
    }

}

const tools: ChatCompletionTool[] = [
    {
        "type": "function",
        "function": {
            "name": "getWorkingHours",
            "description": "Get the current working hours of Barbershop Brooklyn",
            "parameters": {
                "type": "object",
                "properties": {}
            }
        }
    },
    {
        "type": "function",
        "function": {
            "name": "getBarbers",
            "description": "Get the list of barbers working at Barbershop Brooklyn",
            "parameters": {
                "type": "object",
                "properties": {}
            }
        }
    },
    {
        "type": "function",
        "function": {
            "name": "getBusySchedules",
            "description": "Get the busy schedules of barbers at Barbershop Brooklyn",
            "parameters": {
                "type": "object",
                "properties": {}
            }
        }
    },
    {
        "type": "function",
        "function": {
            "name": "getServices",
            "description": "Get the list of available services at Barbershop Brooklyn",
            "parameters": {
                "type": "object",
                "properties": {}
            }
        }
    },
    {
        "type": "function",
        "function": {
            "name": "getBusinessInfo",
            "description": "Get the business information of Barbershop Brooklyn",
            "parameters": {
                "type": "object",
                "properties": {}
            }
        }
    },
    {
        "type": "function",
        "function": {
            "name": "getContactInfo",
            "description": "Get the contact information of Barbershop Brooklyn",
            "parameters": {
                "type": "object",
                "properties": {}
            }
        }
    },
    {
        "type": "function",
        "function": {
            "name": "createOrder",
            "description": "Create a new order with given details",
            "parameters": {
                "type": "object",
                "properties": {
                    "orderDetails": {
                        "type": "object",
                        "properties": {
                            "services": {
                                "type": "array",
                                "items": {
                                    "type": "object",
                                    "properties": {
                                        "service": {"type": "string"},
                                        "barber": {"type": "string"},
                                        "date": {"type": "string", "format": "date"},
                                        "startTime": {"type": "string", "format": "time"},
                                        "endTime": {"type": "string", "format": "time"}
                                    },
                                    "required": ["service", "barber", "date", "startTime", "endTime"]
                                }
                            },
                            "client": {
                                "type": "object",
                                "properties": {
                                    "email": {"type": "string", "format": "email"},
                                    "firstName": {"type": "string"},
                                    "lastName": {"type": "string"},
                                    "phone": {"type": "string"}
                                },
                                "required": ["email", "firstName", "lastName", "phone"]
                            }
                        },
                        "required": ["services", "client"]
                    }
                }
            }
        }
    }
];


