/**
 * Disclaimer: modules in _shims aren't intended to be imported by SDK users.
 */
export class MultipartBody {
  constructor(body) {
    this.body = body;
  }
  get [Symbol.toStringTag]() {
    return 'MultipartBody';
  }
}
