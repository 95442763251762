import { ChatCompletionStream } from "./ChatCompletionStream.mjs";
export class ChatCompletionStreamingRunner extends ChatCompletionStream {
  static fromReadableStream(stream) {
    const runner = new ChatCompletionStreamingRunner(null);
    runner._run(() => runner._fromReadableStream(stream));
    return runner;
  }
  /** @deprecated - please use `runTools` instead. */
  static runFunctions(client, params, options) {
    const runner = new ChatCompletionStreamingRunner(null);
    const opts = {
      ...options,
      headers: {
        ...options?.headers,
        'X-Stainless-Helper-Method': 'runFunctions'
      }
    };
    runner._run(() => runner._runFunctions(client, params, opts));
    return runner;
  }
  static runTools(client, params, options) {
    const runner = new ChatCompletionStreamingRunner(
    // @ts-expect-error TODO these types are incompatible
    params);
    const opts = {
      ...options,
      headers: {
        ...options?.headers,
        'X-Stainless-Helper-Method': 'runTools'
      }
    };
    runner._run(() => runner._runTools(client, params, opts));
    return runner;
  }
}
