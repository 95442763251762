export namespace ChatAction {
    export class SendMessage {
        public static readonly type = '[Chat] Send Message';

        constructor(
            public readonly payload: {
                content: string;
                role: 'user' | 'assistant';
            },
        ) {
        }
    }

    export class ClearHistory {
        public static readonly type = '[Chat] Clear History';
    }
}
