// File generated from our OpenAPI spec by Stainless. See CONTRIBUTING.md for details.
import { APIResource } from "../../resource.mjs";
import * as Core from "../../core.mjs";
export class Translations extends APIResource {
  /**
   * Translates audio into English.
   */
  create(body, options) {
    return this._client.post('/audio/translations', Core.multipartFormRequestOptions({
      body,
      ...options
    }));
  }
}
(function (Translations) {})(Translations || (Translations = {}));
