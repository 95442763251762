// File generated from our OpenAPI spec by Stainless. See CONTRIBUTING.md for details.
import { APIResource } from "../resource.mjs";
import * as Core from "../core.mjs";
export class Images extends APIResource {
  /**
   * Creates a variation of a given image.
   */
  createVariation(body, options) {
    return this._client.post('/images/variations', Core.multipartFormRequestOptions({
      body,
      ...options
    }));
  }
  /**
   * Creates an edited or extended image given an original image and a prompt.
   */
  edit(body, options) {
    return this._client.post('/images/edits', Core.multipartFormRequestOptions({
      body,
      ...options
    }));
  }
  /**
   * Creates an image given a prompt.
   */
  generate(body, options) {
    return this._client.post('/images/generations', {
      body,
      ...options
    });
  }
}
(function (Images) {})(Images || (Images = {}));
