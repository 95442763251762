import {inject, Injectable} from "@angular/core";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {ChatAction} from "./chat.action";
import {OpenaiClientService} from "@ai/external/api/adapter/openai.client.service";

type MessageType = {
    content: string;
    role: 'user' | 'assistant';
};

interface IChatState {
    messages: MessageType[];
    waitForAnswer: boolean;
}

@State<IChatState>({
    name: 'chat',
    defaults: {
        messages: [
            // {
            //   content: 'Hello! Welcome to engravemywine.com. I\'m here to assist you in finding the perfect product with a custom design. Could you please tell me more about the occasion or event you are looking to celebrate with the custom text on the bottle?',
            //   role: 'assistant'
            // }
        ],
        waitForAnswer: false
    }
})
@Injectable()
export class ChatState {

    private readonly openaiClientService = inject(OpenaiClientService);

    @Selector()
    public static messages(state: IChatState): {
        content: string;
        role: 'user' | 'assistant';
    }[] {
        return state.messages;
    }

    @Selector()
    public static waitForAnswer(state: IChatState): boolean {
        return state.waitForAnswer;
    }

    @Action(ChatAction.SendMessage)
    public async sendMessageActionHandler(ctx: StateContext<IChatState>, {payload}: ChatAction.SendMessage): Promise<void> {

        ctx.patchState({
            messages: [
                ...ctx.getState().messages,
                payload
            ],
            waitForAnswer: true
        });

        const {choices: {0: {message}}} = await this.openaiClientService.createChatCompletions([
            ...ctx.getState().messages,
            payload
        ]);

        const answer: MessageType = {
            content: message.content ?? 'No answer provided by AI.',
            role: 'assistant'
        };

        ctx.patchState({
            messages: [
                ...ctx.getState().messages,
                answer
            ],
            waitForAnswer: false
        })

    }

    @Action(ChatAction.ClearHistory)
    public async clearHistoryActionHandler(ctx: StateContext<IChatState>): Promise<void> {

        // ctx.patchState({
        //   messages: [ctx.getState().messages[0]],
        // });

        ctx.patchState({
            messages: [],
        });

    }


}
